<template>
    
  <div
    class="body"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <div class="content">
      <div class="firstbox">
        <div style="margin-bottom: 20px">
          <div class="basic-infomation">
            <div class="title">基础信息</div>
          </div>
          <el-form
            :inline="true"
            :model="leftList"
            class="demo-form-inline"
            label-position="right"
            label-width="83px"
            ref="leftList"
          >
            <el-form-item
              label="模板名称"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="templateName"
            >
              <el-input
                placeholder=""
                v-model="leftList.templateName"
                style="width: 290px"
              >
              </el-input> </el-form-item
            ><br />
            <el-form-item label="计算方式">
              <el-radio v-model="leftList.billingType" :label="1"
                >重量段</el-radio
              >
              <el-radio v-model="leftList.billingType" :label="2"
                >首重续重</el-radio
              ></el-form-item
            ><br />
            <el-form-item label="币种">
              <el-select
                v-model="leftList.currency"
                placeholder="请选择"
                style="width: 290px"
              >
                <el-option
                  v-for="item in currencyList"
                  :key="item.id"
                  :label="item.dicItemValue"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
            ><br />
            <el-form-item label="材积参数">
              <el-input
                placeholder=""
                v-model.number="leftList.volumeParam"
                style="width: 290px"
              >
              </el-input> </el-form-item
            ><br />
            <el-form-item label="运费折扣">
              <el-input
                placeholder=""
                v-model.number="leftList.discount"
                style="width: 290px"
              >
                <span slot="suffix">%</span>
              </el-input> </el-form-item
            ><br />
            <el-form-item label="其他费用">
              <el-checkbox
                v-model="leftList.isRegisteredFee"
                :value="checked"
                :true-label="1"
                :false-label="0"
                >挂号费</el-checkbox
              >
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="leftList.isHandlingFee"
                style=""
                >操作费</el-checkbox
              >

              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="leftList.isAdditionalFee"
                >附加费 </el-checkbox
              ><br /> </el-form-item
            ><br />
            <el-form-item
              label="挂号费折扣"
              v-if="leftList.isRegisteredFee == 1"
            >
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="leftList.isRegisteredFeeDiscount"
                >参与折扣</el-checkbox
              > </el-form-item
            ><br />
          </el-form>
        </div>
        <div class="priceTips">
          <div class="freightTips">
            <span>运费</span>
            <span> = </span>
            <span class="bulge" v-if="leftList.billingType == 1">运费</span>
            <span class="bulge" v-if="leftList.billingType == 2"
              >首重运费 + (包裹计费重 - 首重) / 续重单位重量 x 续重单价</span
            >
            <span v-if="leftList.isRegisteredFee == 1">
              + <span class="bulge">挂号费</span></span
            >
            <span v-if="leftList.isHandlingFee == 1">
              + <span class="bulge">操作费</span></span
            >
            <span v-if="leftList.isAdditionalFee == 1">
              + <span class="bulge">附加费</span></span
            >
          </div>
          <div class="tipsItem">附加费 = 运费 x 附加费率</div>
          <div class="tipsItem">
            最终运费 = 运费 x 运费折扣 x (1 + 附加费率)
          </div>
          <div class="tipsItem">挂号费和操作费按每票计算</div>
        </div>
      </div>
      <div class="thirdbox">
        <div class="basic-infomation">
          <div class="title">运费规则</div>
        </div>
        <div>
          <el-button type="primary" @click="addRule">添加规则</el-button>
        </div>
        <div class="table">
          <el-table
            height="100%"
            :data="tableData"
            style="width: 100%"
            border
            cell-style="text-align:center"
            header-cell-style="text-align:center;background:#f7f8fa"
            :show-overflow-tooltip="true"
          >
            <el-table-column prop="billingType" label="国家/地区" width="width">
            </el-table-column>
            <el-table-column prop="toWeight" label="重量范围" width="width">
            <template slot-scope="scope">
                <span class="ww">{{ scope.row.fromWeight }}g - {{ scope.row.toWeight }}g</span>
            </template>
          </el-table-column>
          <el-table-column prop="fee" label="费用" width="width" v-if="leftList.billingType == 1">
              <template slot-scope="scope">
                <span class="ww">￥{{ scope.row.fee }} /kg</span>
            </template>
          </el-table-column>
          <el-table-column prop="firstWeight" label="首重/首重运费" width="width" v-if="leftList.billingType == 2">
          <template slot-scope="scope">
                <span class="ww">{{ scope.row.firstWeight }} g/￥ {{ scope.row.firstFee }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="continuedUnitWeight" label="续重单位重量/续重单价" width="width" v-if="leftList.billingType == 2">
          <template slot-scope="scope">
                <span class="ww">{{ scope.row.continuedUnitWeight }} g/￥ {{ scope.row.continuedUnitFee }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="registeredFee" label="挂号费" width="width" v-if="leftList.isRegisteredFee == 1">
              <template slot-scope="scope">
                <span class="ww">￥{{ scope.row.registeredFee }}/票</span>
            </template>
          </el-table-column>
          <el-table-column prop="handlingFee" label="操作费" width="width" v-if="leftList.isHandlingFee == 1">
              <template slot-scope="scope">
                <span class="ww">￥{{ scope.row.handlingFee }}/票</span>
            </template>
          </el-table-column>
          <el-table-column prop="additionalRate" label="附加费率" width="width" v-if="leftList.isAdditionalFee == 1">
              <template slot-scope="scope">
                <span class="ww">{{ scope.row.additionalRate }}%</span>
            </template>
          </el-table-column>
            <el-table-column prop="prop" label="操作" width="width">
              <template>
                <el-button
                  type="text"
                  style="font-size: 13px; margin-right: 25px"
                  @click="edit"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  style="font-size: 13px; margin-right: 25px"
                  @click="update()"
                  >复制</el-button
                >
                <el-button
                  type="text"
                  style="font-size: 13px; margin-right: 25px"
                  @click="firstdeleted(index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button style="width: 100px" @click="goback">取消</el-button>
      <el-button type="primary" style="width: 100px" @click="saved"
        >保存</el-button
      >
    </div>
    <!-- 弹框 -->
    <el-dialog
      width="1500px"
      title="添加规则"
      :visible.sync="addBox"
      :close-on-click-modal="false"
    >
      <hr />
      <div style="min-height: 500px">
        <div class="basic-infomation">
          <div class="title">选择国家/地区</div>
        </div>
        <div style="margin-bottom: 30px">
          <el-select
            v-model="rulesList[0].areaCodeList"
            multiple
            placeholder="请选择"
            style="width: 1430px"
          >
            <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.nameCn"
              :value="item.id"
              @click.native = "getname(item)"
            >
            </el-option>
          </el-select>
        </div>
        <div class="basic-infomation">
          <div class="title">设置规则</div>
        </div>
        <el-table
          :data="rulesList[0].rulesItemList"
          style="width: 100%"
          cell-style="text-align:center"
          header-cell-style="text-align:center;background:#f7f8fa"
          :show-overflow-tooltip="true"
        >
          <el-table-column label="重量范围" align="center" fixed>
            <template slot-scope="scope">
              <el-input
                disabled
                style="width: 80px"
                v-model.number="scope.row.fromWeight"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix">g</span> </el-input
              >-
              <el-input
                style="width: 80px"
                v-model.number="scope.row.toWeight"
                placeholder=""
                @change="scope.row.toWeight=scope.row.toWeight<scope.row.fromWeight?Number(scope.row.fromWeight)+1:scope.row.toWeight"
                @blur="inputchange(scope.row,scope.$index)"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix">g</span>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="首重/首重运费"
            align="center"
            fixed
            v-if="leftList.billingType == 2"
          >
            <template slot-scope="scope">
              <el-input
                style="width: 80px"
                v-model.number="scope.row.firstWeight"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix">g</span> </el-input
              >/
              <el-input
                style="width: 80px"
                v-model.number="scope.row.firstFee"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix">￥</span>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="续重单位重量/续重单价"
            align="center"
            fixed
            v-if="leftList.billingType == 2"
          >
            <template slot-scope="scope">
              <el-input
                style="width: 80px"
                v-model.number="scope.row.continuedUnitWeight"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix">g</span> </el-input
              >/
              <el-input
                style="width: 80px"
                v-model.number="scope.row.continuedUnitFee"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix">￥</span>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="费用"
            align="center"
            fixed
            v-if="leftList.billingType == 1"
          >
            <template slot-scope="scope">
              <el-input
                prefix-icon="iconfont icon-RMB"
                style="width: 100px"
                v-model.number="scope.row.fee"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            label="挂号费"
            align="center"
            fixed
            v-if="leftList.isRegisteredFee == 1"
          >
            <template slot-scope="scope">
              <el-input
                prefix-icon="iconfont icon-RMB"
                style="width: 100px"
                v-model.number="scope.row.registeredFee"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="操作费"
            align="center"
            fixed
            v-if="leftList.isHandlingFee == 1"
          >
            <template slot-scope="scope">
              <el-input
                prefix-icon="iconfont icon-RMB"
                style="width: 100px"
                v-model.number="scope.row.handlingFee"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="附加费率"
            align="center"
            fixed
            v-if="leftList.isAdditionalFee == 1"
          >
            <template slot-scope="scope">
              <el-input
                :min="0"
                :max="100"
                style="width: 80px"
                v-model.number="scope.row.additionalRate"
                placeholder=""
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix">%</span>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="prop" label="操作" width="width">
            <template>
              <el-button
                type="text"
                style="font-size: 13px; margin-right: 25px"
                @click="seconddeleted(index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div>
          <el-button type="text" @click="addList">添加规则</el-button>
        </div>
      </div>
      <div style="">
        <el-button @click="addCancle">取 消</el-button>
        <el-button
          type="primary"
          @click="addConfirm"
          style="background-color: #006eff"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCurrency, add, CountryInit } from "@/api/freightAdd";
import { pageInit } from '@/api/goodsManage/CommodityManagement';
export default {
  name:'freightAdd',
  data() {
    return {
      leftList: {
        currency: "",
        billingType: 1,
        templateName: "",
        volumeParam: "",
        discount: "",
        isAdditionalFee: "",
        isHandlingFee: "",
        isRegisteredFee: "",
        isRegisteredFeeDiscount: "",
        rulesList:[],

        
      },
      toWeight:'',
      nameList:[],
      tableData: [],
      addBox: false,
      countryList: [],
      currencyList: [],
      rulesList: [
          {
            areaCodeList:[],
            rulesItemList: [
              {
                fee: "",
                toWeight: 1,
                fromWeight: 0,
                registeredFee: "",
                handlingFee: "",
                additionalRate: "",
                firstWeight: "",
                firstFee: "",
                continuedUnitWeight: "",
                continuedUnitFee: "",
              },
            ],
          },
        ],
    };
  },
  created() {
    this.getData();
    this.getcountryList();
  },
  mounted() {},
  methods: {
    //物流商页面初始化
    async getData() {
      try {
        const res = await getCurrency();
        if ((Object.is(res.code), 200)) {
          this.currencyList = res.data.data;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {}
    },
    async getcountryList() {
      try {
        const res = await CountryInit();
        if ((Object.is(res.code), 200)) {
          this.countryList = res.data.data;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {}
    },
 
    addRule() {
      this.addBox = true;
      this.rulesList = [
          {
            areaCodeList:[],
            rulesItemList: [
              {
                fee: "",
                toWeight: 1,
                fromWeight: 0,
                registeredFee: "",
                handlingFee: "",
                additionalRate: "",
                firstWeight: "",
                firstFee: "",
                continuedUnitWeight: "",
                continuedUnitFee: "",
              },
            ],
          },
        ];

    },
    addCancle() {
      this.addBox = false;
    },

    addConfirm(){
      this.addBox = false;
      this.leftList.rulesList.push(...this.rulesList)
      // this.tableData = this.rulesList[0].rulesItemList;
      const tableDatas = []
      const mergeRule = {}
      console.log("我的", this.leftList.rulesList);
      this.leftList.rulesList.forEach(({ rulesItemList, areaName }) => {
          rulesItemList.forEach(item => {
              item.areaName = areaName
          })
          tableDatas.push(...rulesItemList);
          mergeRule[areaName] = rulesItemList.length;
      })
      this.tableData = tableDatas
      console.log("数据",this.tableData);
      
    },
    saved() {
      add(this.leftList).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    goback(){
      this.$router.push("/freight");
    },
    firstbtn(id) {
      this.firstid = id;
      getwayList(id).then((res) => {
        this.arrs = res.data.data;
      });
    },
    addWay() {
      this.$refs.thirdList.validate((valid) => {
        if (valid) {
          Addway(this.thirdList).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addList() {
      let num = Number(this.rulesList[0].rulesItemList[this.rulesList[0].rulesItemList.length - 1].toWeight)+1
      let obj = {
        fee: "",
        toWeight: "",
        fromWeight:num,
        registeredFee: "",
        handlingFee: "",
        additionalRate: "",
        firstWeight: "",
        firstFee: "",
        continuedUnitWeight: "",
        continuedUnitFee: "",
      }
      this.rulesList[0].rulesItemList.push( obj);

    },
    inputchange(row,index){
      if(index + 1 ==  this.rulesList[0].rulesItemList.length) return
      this.rulesList[0].rulesItemList[index+1].fromWeight = Number(row.toWeight) + 1;

    },
    getname(item){
      this.nameList.push({
        nameCn:item.nameCn,
      })
    },
    firstdeleted(index){
      this.tableData.splice(index, 1);
    },
    seconddeleted(index){
      rulesList[0].rulesItemList.splice(index, 1);
    },
  },
};
</script>

<style lang='scss' scoped>
.firstbox {
  flex-direction: column;
  height: 100%;
  padding: 20px 20px;
  display: flex;
  background-color: #f2f4f7;
  width: 400px;
  border-right: 1px solid #e1e3e8;
}
.thirdbox {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 22px 20px;
  width: calc(100% - 403px);
  .basic-infomation {
    // height: 200px;
  }
}
.body {
  height: 100%;
  margin: 0 !important;
}
.title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  border-left: 2px solid #006eff;
  padding-left: 10px;
  line-height: 14px;
}
.content {
  height: 100%;
  display: flex;
}
.footer {
  height: 80px;
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 4%);
  align-items: center;
  justify-content: center;
}
.priceTips {
  font-size: 12px;
  box-sizing: border-box;
  color: #888;
  padding: 12px;
  background: rgba(0, 110, 255, 0.06);
  border: 1px solid rgba(0, 110, 255, 0.2);
}
.tipsItem {
  color: #888;
  line-height: 20px;
}
.bulge {
  color: #000;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 2px;
  height: 20px;
}
.freightTips {
  // height: 30px;
}
.freightTips span {
  line-height: 30px;
  padding: 2px 4px;
}
.table {
  padding: 20px 4px 0 0;
  height: 700px;
}
.ww{
    font-size: 12px;
}
</style>