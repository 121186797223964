import request from '@/utils/request.js'
export function getCurrency(params) {
    return request({
        url:'/api/freight/template/CurrencyInit',
        method:'get',
        params
    });
}
export function CountryInit(params) {
    return request({
        url:'/api/freight/template/CountryInit',
        method:'get',
        params
    });
}
export function add(data) {
    return request({
        url:'/api/freight/template/add',
        method:'post',
        data
    });
}
